.arrow-download {
    background-image: url('../../../imgs/arrow-download.svg');
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.arrow-left {
    background-image: url('../../../imgs/arrow-left.svg');
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.info {
    background-image: url('../../../imgs/info.svg');
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.frame-334847 {
    background-image: url('../../../imgs/frame.svg');
    width: 22px;
    height: 68px;
    flex-shrink: 0;
}

.pdfIcon {
    background-image: url('../../../imgs/pdfIcon.svg');
    width: 44px;
    height: 62px;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
}

ul.errors_list {
    list-style: none; /* Remove default bullets */
}

ul.errors_list li {
    display: flex;
    flex-direction: row;
}

ul.errors_list li::before {
    content: "\2012";
    color: #FD3564;
    width: 1.5em; /* Also needed for space (tweak if needed) */
}

@media only screen and (max-width: 981px)  {
    .pdfIcon {
        width: 30px;
        height: 40px;
    }
}
