.vpn-notification {
    padding: 20px 16px;
    border-radius: 8px;
    background: #FFF;
    display: flex;
    align-items: flex-start;
    gap: 16px;    
}

.vpn-notification__text {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.vpn-notification__text h5 {
    color: #72BF44;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.vpn-notification__text p {
    color: #242624;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 640px) {
    .vpn-notification {
        padding: 20px 0;
    }
    .vpn-notification__text h5 {
        font-size: 16px;
        line-height: 20px;
    }
    .vpn-notification__text p {
        font-size: 14px;
        line-height: 20px;
    }
}