.armeniaHasEmailBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;
}

.armeniaHasEmailTexts {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.armeniaHasEmailBtns {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.styledLink {
	border: solid 1px #72BF44;
	border-radius: 4px;
	color: #72BF44;
	font-weight: 500 !important;
	padding: 16px 32px 16px 32px;
	text-decoration: none;
	cursor: pointer;
}

.noUnderline {
	text-decoration: none;
	font-weight: 500;
	color: inherit;
}

.MuiLink-underlineAlways {
	text-decoration: none !important;
}

@media (max-width: 768px) {
	.armeniaHasEmailBox {
		justify-content: space-between;
		height: 700px;
	}

	.armeniaHasEmailTexts {
		gap: 32px;
		text-align: center;
	}

	.armeniaHasEmailBtns {
		flex-direction: column;
	}
}

@media (max-width: 1024px) {
	.armeniaHasEmailBtns button{
		height: 82px;
	}
}