.container-footer {
    height: auto;
    max-width: 360px;
    display: flex;
    justify-content: center;
    box-shadow: 0 -4px 16px 0 rgba(95, 126, 204, 0.08);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #FFF;
    z-index: 10;
}

.footer {
    width: 100%;
    max-width: 360px;
    padding: 16px 20px;
    display: flex;
    align-items: flex-start;
}

@media only screen and (min-width: 981px)  {
    .container-footer {
        min-height: 102px;
        max-width: unset;
        width: 100%;
    }

    .footer {
        width: 100%;
        max-width: 1440px;
        padding: 16px 16px 16px 80px;
    }
}
