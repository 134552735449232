/*.MainForm-outer-wrapper{*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    overflow: hidden;*/
/*}*/

/*.MainForm-wrapper{*/
/*    height: calc(100% - 102px);*/
/*    width: 100%;*/
/*    !*min-width: 1280px;*!*/
/*    !*padding: 0 40px;*!*/
/*    !*margin-bottom: max(120px, 10vh);*!*/
/*    overflow-y: auto;*/
/*}*/

.MainForm-wrapper{
    scroll-margin-top: 4rem;
}

.MainForm {
    /*height: 100%;*/
    width: 100%;
    max-width: 1440px;
    padding: 0 16px 48px;
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 460px)  {
    .MainForm {
        padding: 0 2rem 48px;
    }
}

@media only screen and (min-width: 640px)  {
    .MainForm {
        padding: 0 40px;
    }
}
