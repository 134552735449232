.need-help-btn {
    padding: 4px 12px;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.need-help-btn-text {
    font-family: "GT America LCG";
    color: var(--Light-Mode-Text-secondary, #6B7782);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

@media (max-width: 640px) {
    .need-help-btn {
        padding: 0;
    }
    .need-help-btn-text {
        font-size: 12px;
    }    
}