.container-header-beliz {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
}

.header-beliz {
    height: 100%;
    width: 360px;
    max-width: 1440px;
    padding: 8px 20px;
    background-color: #FFFFFF;
    font-family: "GT America LCG";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-beliz {
    background-image: url('../../imgs/beliz_header.svg');
    background-repeat: no-repeat;
    width: 112px;
    height: 24px;
    flex-shrink: 0;
}

.menu-beliz {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*gap: 40px;*/
}

.cta {
    display: none;
}

.language-select-beliz {
    display: flex;
    gap: 4px;
    color: #2A3E5C;
    cursor: pointer;
    align-items: center;
}

.language-icon {
    background-image: url('../../imgs/planet.svg');
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.arrow-icon {
    background-image: url('../../imgs/arrow-down.svg');
    align-self: center;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

#phone_button {
    display: none;
}

.phone_icon {
    background-image: url('../../imgs/beliz_phone.svg');
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

@media (min-width: 981px) {
    .container-header-beliz {
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #F4F8FF;
    }

    .header-beliz {
        height: 100%;
        width: 100%;
        max-width: 1440px;
        padding: 20px 80px 12px;
        background-color: #F4F8FF;
        /*font-family: "GT America LCG";*/
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
    }

    .logo-beliz {
        background-image: url('../../imgs/beliz_header.svg');
        width: 224px;
        height: 41px;
        flex-shrink: 0;
    }

    .menu-beliz {
        width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 80px;
    }

    .cta {
        width: 247px;
        display: flex;
    }

    .language-select-beliz {
        display: flex;
        gap: 4px;
        color: #2A3E5C;
        cursor: pointer;
    }

    .language-icon {
        background-image: url('../../imgs/planet.svg');
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }

    .arrow-icon {
        background-image: url('../../imgs/arrow-down.svg');
        align-self: center;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

    #phone_button {
        padding: 12px 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        display: flex;
        flex-shrink: 0;
    }

    .phone_icon,
    .phone_icon_link {
        display: none;
    }

}


