.armenia_arrow-left-green {
    background-image: url('../../../imgs/arrow-left-xs.svg');
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.pdfIcon_green {
    background-image: url('../../../imgs/pdfIcon_green.svg');
    width: 44px;
    height: 62px;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    color: #72BF44;
}

@media only screen and (min-width: 640px)  {
    .armenia_arrow-left-green {
        background-image: url('../../../imgs/arrow-left-green.svg');
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }
}
