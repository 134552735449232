.AppFooter {
    max-height: 157px;
    min-height: 110px;
    height: 9vh;
    width: 100%;
    background-image: url('/src/imgs/app_footer.svg');
    position: fixed;
    color: white;
    bottom: 0;
}

.TextItems {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    margin-bottom: 10px;
    width: 100%;
}

.Сonfidential {
    margin-left: 120px;
}

.Сopyright {
    position: absolute;
    right: 50px;
}

@media screen and (max-width: 1000px),
screen and (min-height: 800px) {
    .TextItems {
        flex-direction: column;
        text-align: right;
        right: 30px;
    }

    .Сonfidential {
        margin-left: 0px;
        left: 10px;
        position: relative;
    }

    .Сopyright {
        position: relative;
        left: 10px;
    }
}
