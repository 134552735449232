@font-face {
  font-family:'GT America LCG';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('GT America LCG'), url("./assets/fonts/GT-America-LC-Standard-Regular.woff") format('woff'),
  url("./assets/fonts/GT-America-LC-Standard-Regular.woff2") format('woff2');
}

@font-face {
  font-family:'GT America LCG';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('GT America LCG'), url("./assets/fonts/GT-America-LC-Standard-Medium.woff") format('woff'),
  url("./assets/fonts/GT-America-LC-Standard-Medium.woff2") format('woff2');
}

@font-face {
  font-family:'GT America LCG';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('GT America LCG'), url("./assets/fonts/GT-America-LC-Standard-Bold.woff") format('woff'),
  url("./assets/fonts/GT-America-LC-Standard-Bold.woff2") format('woff2');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-family: "GT America LCG", Arial, sans-serif;
}

html, body {
  height: auto;
}

body {
  font-family: "GT America LCG", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* чтобы не появлялась вторая иконка-глаз видимости введёного пароля */
input::-ms-reveal,
input::-ms-clear {
	display: none;
}

/*#root{*/
/*  height: 100%;*/
/*}*/
