.AppHeader {
    height: 17vh;
    max-height: 285px;
    min-height: 100px;
    width: 100%;
    background-image: url('../../imgs/Header.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /*object-fit: fill;*/
    position: relative;
    display: flex;
    flex-direction: column;
}


.AppLogo {
    background-image: url('../../imgs/app_logo.svg');
    background-repeat: no-repeat;
    width: 287px;
    height: 28px;
    min-height: 28px;
    position: relative;
    background-size: contain;
    object-fit: contain;
    margin-top: 3vh;
    margin-left: 7vw;
}

.HeaderText {
    color: white;
    font-size: 1.5em;
    width: 30%;
    text-align: left;
    position: relative;
    margin-top: 2vh;
    margin-left: 7vw;
    vertical-align: top;
    line-height: 1em;
}

.ContactPhone {
    font-size: .9em;
    color: white;
    text-align: left;
    position: absolute;
    width: 15em;
    right: 0px;
    margin-top: 2vh;
}

@media only screen and (min-width: 1000px) and (max-height: 1100px) {
    .AppHeader {
        flex-direction: row;
    }

    .HeaderText {
        margin-left: 10vw;
        margin-top: 2vh;
    }
}

@media screen and (max-width: 1000px),
screen and (min-height: 800px) {
    .AppHeader {
        flex-direction: column;
        min-height: 160px;
    }

    .AppLogo {
        margin: 2vh 2vw;
    }

    .HeaderText {
        font-size: 1.3em;
        width: 80%;
        margin: 1vh 2vw;
    }

    .ContactPhone {
        right: auto;
        position: relative;
        margin-top: 0.5vh;
        margin-left: 2vw;
    }
}
