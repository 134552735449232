.age-notification_box {
    border-radius: 12px;
    background: #FFF;    
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
}

.age-notification_text {
    color: var(--text-secondary, #6B7782);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 640px) {
    .age-notification_box {
        background: #F6F6F6;
    }
    .age-notification_text {
        font-size: 14px;
        line-height: 16px;
    }
}